import React, { useEffect, useState } from "react";
import { getOffers } from "../../utils/api";
import serviceHero from "../../assets/serviceImg.jpeg";
import serviceGuy from "../../assets/guydoingService.jpeg";
import clock from "../../assets/clock.png";
import hand from "../../assets/hand.png";
import car1 from "../../assets/Car1Vector.png";
import car1back from "../../assets/car1back.png";
import car2 from "../../assets/car2.png";
import car2back from "../../assets/car2back.png";
import car3 from "../../assets/car3.png";
import car3back from "../../assets/car3back.png";
import car4 from "../../assets/car4.png";
import car4back from "../../assets/car4back.png";
import gallery1 from "../../assets/serviceGallery1.jpeg";
import gallery2 from "../../assets/serviceGallery2.jpeg";
import gallery3 from "../../assets/serviceGallery3.jpeg";
import gallery4 from "../../assets/serviceGallery4.jpeg";

const Service = () => {
  const [apiData, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOffers();
        setData([data.data[0], data.data[1], data.data[2]]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="pt-[85px] bg-black">
      <div className="  relative h-[480px] pl-[50px] md:px-[20px] md:pt-[30px] z-[10] flex flex-col justify-center md:justify-start overflow-hidden ">
        <img src={serviceHero} className=" absolute min-w-[1000px] z-[-1] right-0 bottom-0 " alt="" />
        <h2 className="z-5 text-white font-[900] text-[40px] leading-[48px] mb-[20px] ">
          Shërbim i plotë për veturën tuaj
        </h2>
        <p className=" text-white max-w-[630px] z-5 font-[500] text-[15px] leading-[20px] ">
          Në servisin tonë, kujdesemi për çdo detaj që lidhet me mirëmbajtjen dhe funksionalitetin e veturës tuaj. Nga kontrollet bazë te sistemet mekanike dhe elektrike, deri te zëvendësimi i vajit të motorit dhe filtrave, ne mbulojmë më shumë se 50 aspekte për ta mbajtur veturën tuaj në gjendje perfekte.
        </p>
      </div>
      <div className=" flex gap-[50px] bg-[#2A2A2A] flex-wrap relative w-[100%] min-h-[208px] pt-[60px] pb-[30px] justify-center items-start ">
        <h3 className=" font-[900] text-white absolute text-[15px] top-[40px] ">
          Orari i Punës:
        </h3>
        <div className=" flex flex-col items-center mt-[20px]">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Martë
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Mërkure
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Enjëte
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Hëne
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Premte
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Shtunë
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={hand} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Diele
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">Mbyllur</h4>
        </div>
      </div>
      <div className=" px-[200px] md:px-[20px] ">
        <h2 className="z-5 text-white font-[900] mt-[100px] text-[40px] leading-[48px] mb-[20px] ">
          Brendet që i shërbejmë:
        </h2>
        <p className=" text-white z-5 font-[500] text-[15px] leading-[20px] ">
          Mercedes, BMW, Volkswagen dhe Audi janë emra që përfaqësojnë standardin e përsosmërisë, dhe ne jemi këtu për t’u siguruar që çdo veturë të funksionojë në maksimum. Me një kontroll të detajuar që përfshin më shumë se 50 komponentë të sistemit, ne ofrojmë rregullime dhe zëvendësime që ruajnë cilësinë origjinale të këtyre brendeve elitare.
        </p>
        <div className=" flex gap-[4%] justify-center md:flex-col ">
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] md:w-[100%] h-[467px] ">
            <img src={car1} alt="" className=" my-[30px] " />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
            Mercedes përfaqëson luksin dhe performancën, dhe shërbimi ynë është krijuar për të garantuar që çdo detaj i veturës tuaj të ruajë standardet e saj të jashtëzakonshme.

            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-2] h-[100%] "
              src={car1back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] md:w-[100%] h-[467px] ">
            <img src={car2} alt="" className=" my-[30px] " />
            <div style={{background:'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)'}} className=' w-[100%] absolute left-0 top-0 h-[250px] max-h-[100%] z-[-1] ' />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              BMW përfaqëson fuqinë, teknologjinë dhe elegancën, dhe ne sigurojmë që vetura juaj të qëndrojë gjithmonë në kulmin e performancës.
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-2] h-[100%] "
              src={car2back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] md:w-[100%] h-[467px] ">
            <img src={car3} alt="" className=" my-[30px] " />
            <div style={{background:'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)'}} className=' w-[100%] absolute left-0 top-0 h-[250px] max-h-[100%] z-[-1] ' />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Volkswagen, me historinë e gjatë të inxhinierisë së përkryer, ka fituar besimin e miliona shoferëve në mbarë botën. Shërbimi për Volkswagen pasqyron standardet më të larta, duke siguruar efikasitet dhe performancë në çdo detaj.
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-2] h-[100%] "
              src={car3back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] md:w-[100%] h-[467px] ">
            <img src={car4} alt="" className=" my-[30px] " />
            <div style={{background:'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)'}} className=' w-[100%] absolute left-0 top-0 h-[250px] max-h-[100%] z-[-1] ' />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Audi përfaqëson luksin, performancën dhe teknologjinë e avancuar – dhe servisi ynë është i përkushtuar për t’i ruajtur këto standarde. Çdo vizitë në servis përqendrohet në mirëmbajtjen dhe optimizimin e çdo detaji të veturës tuaj.
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-2] h-[100%] "
              src={car4back}
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div className=" flex ">
          <img
            src={gallery1}
            className="w-[70%] md:w-[55%] h-[522px] md:h-[200px] object-cover "
            alt=""
          />
          <img
            src={gallery2}
            className="w-[30%] md:w-[45%] h-[522px] md:h-[200px] object-cover "
            alt=""
          />
        </div>
        <div className=" flex ">
          <img
            src={gallery4}
            className="w-[40%] md:w-[45%] h-[522px] md:h-[200px] object-cover "
            alt=""
          />
          <img
            src={gallery3}
            className="w-[60%] md:w-[55%] h-[522px] md:h-[200px] object-cover "
            alt=""
          />
        </div>
      </div>
      <div className="  relative w-[100%] aspect-[1.9] z-[10] min-h-[600px] flex flex-col justify-start mt-[80px] overflow-hidden ">
        <img src={serviceGuy} className=" absolute z-[-1] left-0 bottom-0 w-[100%] min-w-[1000px] " alt="" />
        <h2 className="z-5 text-white font-[900] text-[40px] ml-[60px] leading-[48px] mb-[20px] ">
          Servisim i sigurtë për veturën tuaj.
        </h2>
        <p className=" text-white max-w-[800px] z-5 font-[500] text-[15px] ml-[60px] mr-[100px] leading-[20px] ">
        Shërbimet tona mbulojnë gjithçka: nga ndërrimi i vajit dhe filtrave, kontrolli i gomave dhe dritave, deri te diagnostifikimi dhe riparimi i problemeve mekanike dhe elektronike. Çdo detaj i veturës suaj trajtohet me përkujdesje maksimale, duke ruajtur standardet më të larta të cilësisë dhe sigurisë.
        </p>
      </div>
    </div>
  );
};

export default Service;
